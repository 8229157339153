.footer {
  padding: 70px 0 80px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.footer__logo__container {
  display: flex;
  align-items: center;
  gap: 18px;
  margin-bottom: 10px;
  cursor: pointer;
  transition: 0.3s;
}

.footer__logo__container svg {
  transition: 0.3s;
  width: 55px;
}

.footer__logo__text {
  font-size: 40px;
  position: relative;
  top: 5.5px;
  line-height: 34px;
  font-weight: 300;
  transition: 0.3s;
}

.footer__logo__licence {
  font-size: 0.9rem;
}

.footer__columns {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 50px;
}

.footer__column__link {
  color: var(--gray);
  transition: 0.2s;
}

.footer__column__link:hover {
  color: black;
}

.footer__column {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 0.9rem;
}

.footer__column__wrapper {
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.footer__column__title {
  font-weight: 600;
  color: var(--purple);
  font-size: 1rem;
}

.footer__column__line__group {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.footer__column__line {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: var(--gray);
}

.footer__column__line__icon {
  position: relative;
  top: 5px;
  width: 15px;
  height: 15px;
}

.footer__column__line__groups {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.footer__button {
  color: var(--purple);
  font-weight: 600;
  padding: 10px 80px;
  border: 3px solid var(--purple);
  border-radius: 89px;
  font-size: 1.1rem;
  transition: 0.3s;
}

.footer__button:hover {
  cursor: pointer;
  color: white;
  background: var(--purple);
  border-radius: 17px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.2);
}

.footer__logo__container:hover .footer__logo__text {
  transform: translateX(3px);
}

@media screen and (max-width: 1380px) {
  .footer {
    flex-direction: column;
    align-items: center;
    padding: 0 0 40px;
  }
  .footer__item {
    max-width: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .footer__columns {
    flex-direction: column;
    margin-top: 30px;
  }
  .footer__column__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .footer__column__line__groups {
    flex-direction: row;
    gap: 30px;
  }
  .footer__item.logo {
    order: 3;
    margin-top: 30px;
    align-items: flex-start;
  }
}
