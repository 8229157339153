.section__intro {
  font-family: var(--secondary-ff);
  font-size: 32px;
  margin: 40px 0;
}

@media screen and (max-width: 700px) {
  .section__intro {
    margin: 25px 0;
    font-size: 1.4rem;
  }
}
