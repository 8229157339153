.business__column {
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  width: 100%;
}

.business__column__upper {
  display: flex;
  align-items: center;
  gap: 20px;
  background: var(--purple);
  border-radius: 26px;
  padding: 30px;
  color: white;
}

.business__column__upper__icon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.business__column__upper__info {
  display: flex;
  flex-direction: column;
}

.business__column__upper__info__title {
  font-size: 1.1rem;
  font-weight: 500;
}

.business__column__upper__info__text {
  font-weight: 300;
}

.business__column__info {
  padding: 20px;
}

.info__item {
  width: 100%;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  padding: 20px;
  min-height: 180px;
}

.info__item__upper {
  display: flex;
  align-items: center;
  gap: 15px;
}

.info__item__upper__icon {
  width: 45px;
  height: 45px;
  border-radius: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.info__item__upper__title {
  font-weight: 500;
}

.info__item__text {
  margin-top: 10px;
  font-size: 0.9rem;
}
