.home__description {
  margin: 10px 0 20px 15px;
  max-width: 50%;
  width: 100%;
  font-size: 1.1rem;
  font-weight: 300;
  user-select: none;
}

.home__title {
  color: black !important;
  font-size: 120px !important;
  text-shadow: 1px 1px 1px black;
  margin-bottom: 20px;
}

.home__title svg {
  position: relative;
  top: 4px;
  width: 150px;
  margin-right: 30px;
  height: 110px;
}

@media screen and (max-width: 700px) {
  .home__description {
    max-width: 100%;
    font-size: 0.9rem;
  }
}
