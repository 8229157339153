.ignites__grid {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
}

.ignites__item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
}

.ignites__item__number {
  width: 100%;
  border-radius: 14px;
  background: var(--purple);
  color: white;
  font-weight: 700;
  padding: 15px 20px;
  font-size: 50px;
}

.ignites__item__text {
  padding: 20px 10px;
  font-weight: 500;
}

@media screen and (max-width: 1150px) {
  .ignites__grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }

  .ignites__item__text {
    height: 60px;
    display: flex;
    align-items: center;
  }
  .ignites__item:nth-child(1) {
    grid-area: 1 / 1 / 1 / 2;
  }
  .ignites__item:nth-child(2) {
    grid-area: 1 / 2 / 1 / 3;
  }
  .ignites__item:nth-child(3) {
    grid-area: 2 / 1 / 2 / 2;
  }
  .ignites__item:nth-child(4) {
    grid-area: 2 / 2 / 2 / 3;
  }
  .ignites__item:nth-child(5) {
    grid-area: 3 / 1 / 3 / 3;
  }
}
