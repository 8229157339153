.title__section {
  display: flex;
  gap: 12px;
  background: var(--purple);
  padding: 50px;
  border-radius: 45px;
}

.title__section p {
  color: white;
  margin-left: 5px;
}

@media screen and (max-width: 1100px) {
  .title__section {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 10px !important;
    padding: 25px;
    border-radius: 26px;
  }
}
