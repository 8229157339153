* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --primary-ff: "Poppins", sans-serif;
  --secondary-ff: "Gilroy";
  --purple: #5200ff;
  --red: #e0522b;
  --orange: #ffa800;
  --green: #00b955;
  --gray: #575757;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  outline: none;
  border: none;
  font-family: var(--primary-ff);
  background: inherit;
}

input,
textarea {
  outline: none;
  border: none;
  font-family: var(--primary-ff);
}

textarea {
  resize: none;
}

html,
body {
  overflow-x: hidden;
}

body {
  font-family: var(--primary-ff);
}

.scrollbar-track {
  z-index: 1001 !important;
}

.scrollbar-thumb {
  z-index: 1002 !important;
}

.app {
  padding-top: 130px;
  width: 100vw;
  height: 100vh;
}
