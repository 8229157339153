.row__item {
  display: flex;
  align-items: center;
  min-height: 100px;
  gap: 30px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  width: 100%;
  min-height: 100px;
}

.row__item__icon__container {
  height: 100px;
  width: 100px;
  border-radius: 14px;
  background: var(--purple);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: 0.3s;
}

.row__item__text {
  font-weight: 300;
  padding: 20px 30px 20px 0;
  font-size: 0.95rem;
  transition: 0.3s;
}

.row__item:hover .row__item__icon__container {
  transform: translateX(5px);
}

.row__item:hover .row__item__text {
  transform: translateX(5px);
}

@media screen and (max-width: 1000px) {
  .row__item {
    width: 100% !important;
    gap: 20px;
  }
  .row__item__text {
    font-size: 0.8rem;
    padding: 20px 10px 20px 0;
  }
}
