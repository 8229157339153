.fixed__button {
  width: 60px;
  height: 60px;
  background: var(--purple);
  border-radius: 50%;
  box-shadow: 0px 0px 41px rgba(0, 0, 0, 0.53);
  position: fixed;
  left: 50%;
  bottom: 30px;
  display: flex;
  opacity: 0;
  appearance: none;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  transition: 0.2s;
  transform: translateX(-50%) translateY(10px);
}

.visible {
  opacity: 1;
  appearance: unset;
  pointer-events: all;
  transform: translateX(-50%) translateY(0);
}
