.title {
  font-family: var(--secondary-ff);
  font-weight: 300;
  font-size: 85px;
  line-height: 101.5%;
  color: white;
  user-select: none;
}

@media screen and (max-width: 1400px) {
  .title {
    font-size: 4.5rem;
  }
}

@media screen and (max-width: 700px) {
  .title {
    font-size: 3rem;
    word-spacing: 100vw;
  }
}
