.contacts__map {
  max-width: 1000px;
  width: 100%;
  display: flex;
  height: 320px;
  border-radius: 26px;
}

.contacts__info {
  max-width: 700px;
  width: 100%;
}

.contacts__info__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  border-radius: 26px;
  min-height: 150px;
  width: 100%;
}

.contacts__info__item__info {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.contacts__info__item__line {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  margin-top: 7px;
}
.contacts__info__item__line svg {
  position: relative;
  top: 3px;
}

.contacts__info__item__title {
  font-weight: 500;
}

@media screen and (max-width: 700px) {
  .contacts__info__item {
    padding: 20px 30px;
    align-items: flex-start;
    min-height: unset;
    border-radius: 18px;
  }
}
