.quote__container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 50px;
  border-radius: 40px;
  font-size: 1.1rem;
}

.quote {
  max-width: 600px;
  width: 100%;
  text-align: center;
  color: white;
}

@media screen and (max-width: 700px) {
  .quote__container {
    border-radius: 20px;
    font-size: 0.95rem;
  }
}
