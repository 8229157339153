.row {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  user-select: none;
}

@media screen and (max-width: 1000px) {
  .row {
    flex-direction: column;
  }
}
