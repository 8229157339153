.container {
  max-width: 1920px;
  width: 100%;
  padding: 0 75px;
  margin: 0 auto;
}

@media screen and (max-width: 1000px) {
  .container {
    padding: 0 25px;
  }
}
