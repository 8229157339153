.contact__form {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 35px;
  padding: 30px 30px 80px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
  border-radius: 28px;
  margin-top: 50px;
}

.contact__form__intro {
  display: flex;
  flex-direction: column;
}

.contact__form__intro__title {
  font-size: 70px;
  font-family: var(--secondary-ff);
  margin-bottom: 20px;
}

.contact__form__inputs {
  display: flex;
  gap: 5px;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
}

.contact__form__textarea__container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  max-width: 400px;
  width: 100%;
}

.contact__form__input {
  border-radius: 40px;
  margin-bottom: 10px;
}

.contact__form__input:focus,
.contact__form__textarea:focus {
  border-radius: 10px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.05);
}

.contact__form__input,
.contact__form__textarea {
  background: #ebebeb;
  padding: 10px 20px;
  transition: 0.2s;
}

.contact__form__textarea {
  height: 210px;
  border-radius: 20px;
}

.contact__form__input::placeholder,
.contact__form__textarea::placeholder {
  color: #b6b6b6;
  font-weight: 300;
}

.contact__form__button {
  position: absolute;
  bottom: 30px;
  right: 30px;
  background: var(--green);
  color: white;
  font-weight: 600;
  border-radius: 40px;
  padding: 4px 80px;
  font-size: 1rem;
  transition: 0.2s;
}

.contact__form__button:hover {
  border-radius: 10px;
  box-shadow: 0px 4px 42px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1450px) {
  .contact__form__intro__title {
    font-size: 3rem;
  }
  .contact__form__intro__text {
    font-size: 0.8rem;
  }
  .contact__form {
    flex-direction: column;
    margin: 20px auto 0;
    max-width: 600px;
    width: 100%;
    gap: 15px;
  }
  .contact__form__button {
    right: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 150px;
    padding: 4px 10px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .contact__form__input__name,
  .contact__form__input {
    font-size: 0.9rem;
  }
}
